import React from "react";

import { useConfig } from "gatsby-theme-advanced";

import Logo from "../../icons";

import * as S from "./style";

const Navigation = (): JSX.Element => {
  const config = useConfig();

  return (
    <S.Wrapper>
      <S.HomeButton to="/">
        <Logo width={36} height={36} />
        <S.SiteTitle>{config.website.titleShort}</S.SiteTitle>
      </S.HomeButton>
      <S.NavGrid>
        <S.NavButton noBasePath to="/">
          Home
        </S.NavButton>
        <S.NavButton noBasePath to="/category/projects">
          Projects
        </S.NavButton>
        <S.NavButton noBasePath to="/photos">
          Photos
        </S.NavButton>
        <S.NavButton noBasePath to="/category/blog">
          Blog
        </S.NavButton>
      </S.NavGrid>
    </S.Wrapper>
  );
};

export default Navigation;
